<template>
  <b-overlay
    id="overlay-background"
    :show="!Object.keys(patientData).length"
    :variant="$overlayConfig.variant"
    opacity="0.90"
    blur="2px"
    rounded="sm"
  >
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="patientData.avatar"
              :text="avatarText(`${patientData.prenom} ${patientData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(patientData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ patientData.prenom }} {{ patientData.nom }}
                </h4>
                <span class="card-text">{{ patientData.telephone }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="{ name: 'apps-users-edit', params: { id: patientData.id } }"
                  variant="primary"
                >
                  Modifier
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                >
                  Supprimer
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  23.3k
                </h5>
                <small>Frais Medical du mois</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="TrendingUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  $99.87k
                </h5>
                <small>Frais Medical du Total</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75 text-primary"
                />
                <span class="font-weight-bold">Date de naissance</span>
              </th>
              <td class="pb-50">
                {{ patientData.date_naissance }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75 text-success"
                />
                <span class="font-weight-bold">Sexe</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ patientData.sexe }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75 text-danger"
                />
                <span class="font-weight-bold">Adresse</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ patientData.adresse }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75 text-primary"
                />
                <span class="font-weight-bold">Téléphone</span>
              </th>
              <td class="pb-50">
                {{ patientData.telephone }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="DropletIcon"
                  class="mr-75 text-danger"
                />
                <span class="font-weight-bold">Group sanguin</span>
              </th>
              <td>
                {{ patientData.contact }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-75 text-warning"
                />
                <span class="font-weight-bold">Allergies</span>
              </th>
              <td>
                {{ patientData.contact }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="HeartIcon"
                  class="mr-75 text-danger"
                />
                <span class="font-weight-bold">Maladies chronique</span>
              </th>
              <td>
                {{ patientData.contact }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="ShieldIcon"
                  class="mr-75 text-primary"
                />
                <span class="font-weight-bold">Assurance</span>
              </th>
              <td>
                {{ patientData.contact }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75 text-primary"
                />
                <span class="font-weight-bold">Rendez-vous</span>
              </th>
              <td>
                {{ patientData.contact }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BOverlay, BRow,
} from 'bootstrap-vue'
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import { avatarText } from '@core/utils/filter'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { OVERLAY_CONFIG as $overlayConfig } from '@/helpers/global-scops'

export default {
  name: 'PatientInfo',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BOverlay,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    $overlayConfig() {
      return $overlayConfig
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  created() {
  },
  methods: { light },
}
</script>

<style scoped>

</style>
