<template>
  <div>

    <!-- First Row -->
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="8"
      >
        <patient-info
          :patient-data="patient"
        />
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <consultations-time-line :consultation-data="consultations" />

      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import PatientInfo from '@/views/pages/dossiers_patients/details/patientInfo.vue'
import { getPatient } from '@/api-services/dossiers_patients'
import ConsultationsTimeLine from '@/views/pages/dossiers_patients/details/ConsultationsTimeLine.vue'

export default {
  components: {
    ConsultationsTimeLine,
    PatientInfo,
    BRow,
    BCol,
  },
  data() {
    return {
      patient: {},
      consultations: [],
    }
  },
  created() {
    this.getPatient()
  },
  methods: {
    getPatient() {
      setTimeout(() => {
        getPatient(this, this.$route.params.id).then(response => {
          this.patient = response.data
          console.log(this.patientData)
        })
        this.consultations = [{}]
      }, 2000)
    },
  },
}
</script>

<style>

</style>
